import { useEffect, useRef, useState } from "react";
import { Banner } from "@smartrent/ui";
import {
  ContentOptions,
  createEmbeddingContext,
  EmbeddingContext,
  FrameOptions,
} from "amazon-quicksight-embedding-sdk";

import { fetchQuickSightDashboardEmbedUrl } from "@/api/quicksight-dashboards";

import { LoadingDots } from "./LoadingDots";

interface QuickSightDashboardFilter {
  Name: string;
  Values: string[];
}

interface QuickSightDashboardProps {
  id: "client-dashboard"; // | 'iot' | 'tours';
  filters?: QuickSightDashboardFilter[];
  style?: React.CSSProperties;
}

export const QuickSightDashboard = ({
  id,
  filters,
  style,
}: QuickSightDashboardProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dashboard, setDashboard] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let embeddingContext: EmbeddingContext;
    setError(null);
    if (!containerRef.current) {
      console.warn("Quicksight embedding container not found.");
      return;
    }
    (async () => {
      try {
        embeddingContext = await createEmbeddingContext();
        const embedUrlData = await fetchQuickSightDashboardEmbedUrl(id);

        if (!embedUrlData || !embedUrlData.EmbedUrl) {
          console.log(
            "Error loading dashboard",
            embedUrlData,
            containerRef.current
          );
          setLoading(false);
          setError("Error loading dashboard. Reload the page to try again.");
          return;
        }

        const frameOptions: FrameOptions = {
          url: embedUrlData.EmbedUrl,
          container: containerRef.current,
          height: `${containerRef.current.clientHeight}`,
          width: `${containerRef.current.clientWidth}`,
        };

        const contentOptions: ContentOptions = {
          parameters: [
            {
              Name: "cmwAccount",
              Values: [],
            },
            {
              Name: "cmwProperty",
              Values: [],
            },
            {
              Name: "startDate",
              Values: [],
            },
            {
              Name: "endDate",
              Values: [],
            },
          ],
          locale: "en-US",
          sheetOptions: {
            singleSheet: false,
            emitSizeChangedEventOnSheetChange: false,
          },
          toolbarOptions: {
            export: false,
            undoRedo: false,
            reset: false,
          },
          attributionOptions: {
            overlayContent: false,
          },
        };
        const embeddedDashboardExperience =
          await embeddingContext.embedDashboard(frameOptions, contentOptions);
        setDashboard(embeddedDashboardExperience);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("Error loading dashboard", error);
        setError("Error loading dashboard. Reload the page to try again.");
      }
    })();
  }, [id, containerRef]);

  useEffect(() => {
    if (dashboard && filters) {
      dashboard.setParameters(filters);
    }
  }, [dashboard, filters]);

  if (error) {
    return (
      <div className="py-1 px-2" style={style}>
        <Banner color="error">{error}</Banner>
      </div>
    );
  }

  return (
    <div id={`aws-qs-${id}`} ref={containerRef} style={style}>
      {loading && (
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            ...style,
          }}
        >
          <LoadingDots />
        </div>
      )}
    </div>
  );
};
