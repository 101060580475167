import { AxiosError } from "axios";
import warning from "tiny-warning";

export const getErrorMessage = (
  error: AxiosError<any>,
  defaultError = "Something went wrong."
): string => {
  const errorData = error?.response?.data?.error;
  const isObjectWithDescription = (
    data: any
  ): data is { description?: string } =>
    typeof data === "object" && data !== null && "description" in data;
  const isString = typeof errorData === "string";

  warning(
    isObjectWithDescription(errorData) || isString,
    `Expected error.response.data.error to be of type string or object with description, got "${typeof errorData}"`
  );

  if (isObjectWithDescription(errorData)) {
    return errorData.description ?? JSON.stringify(errorData);
  }

  if (isString) {
    return errorData;
  }

  return defaultError;
};

export const isAxiosError = (err: any): err is AxiosError =>
  typeof err === "object" && "isAxiosError" in err && err.isAxiosError;
