export const modules = [
  { label: "Alloy Access", value: "Alloy Access" },
  { label: "Alloy Access - Salto", value: "Alloy Access - Salto" },
  { label: "Self Guided Tours", value: "Self Guided Tours" },
  { label: "SmartHome", value: "SmartHome" },
  { label: "Alloy WiFi", value: "Alloy Wifi" },
  { label: "Parking", value: "Parking" },
  // The following features are disabled while we figure out a performant way to do this.
  // { label: "Guest Parking Enabled", value: "Guest Parking Enabled" },
  // {
  //   label: "Guest Parking Payments Enabled",
  //   value: "Guest Parking Payments Enabled",
  // },
];
