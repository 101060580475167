export default function PublicPageLayout({ children }) {
  return (
    <div
      style={{
        height: "100vh",
        background:
          "url(https://control.smartrent.com/images/public-background-bdb7480b17f9250ea75fff652b7d4c6a.jpg?vsn=d) 50% no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{ background: "rgb(26, 31, 39, 0.8)", height: "100vh" }}
        className="page"
      >
        <div
          className="page__content page__content--centered flex flex-col items-center justify-center"
          style={{ opacity: 1 }}
        >
          <div className="mb-12 flex items-center space-x-3">
            <img
              className="h-11"
              src="/images/logo.svg"
              alt="SmartRent Support"
            />
            <span className="text-[#00ACA0] text-5xl font-bold">
              SupportRent
            </span>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
