type Props = {
  color: string; // hex,
  size?: number;
  style?: any;
};

export default function Circle({ color, size, style }: Props) {
  return (
    <span
      style={{
        backgroundColor: color,
        borderRadius: 10,
        display: "inline-block",
        marginRight: 5,
        width: size || 10,
        height: size || 10,
        ...style,
      }}
    />
  );
}
