import Toast from "@/common/Toast";
import Loading from "@/common/Loading";

import { ScrollArea } from "@/_v2/components/ui/scroll-area";

import { cn } from "../utils";

import { Header } from "./layout-header/layout-header";

interface LayoutV2Props extends React.PropsWithChildren {
  fullScreen?: boolean;
}

export const LayoutV2: React.FC<LayoutV2Props> = ({ children, fullScreen }) => {
  return (
    <>
      <Header />
      <Loading>
        <ScrollArea className="h-[calc(100%-40px)]">
          <div
            className={cn({
              "p-4": !fullScreen,
            })}
          >
            {children}
          </div>

          <div
            className={cn({
              "h-32": !fullScreen,
            })}
          />
        </ScrollArea>
      </Loading>
      <Toast />
    </>
  );
};
