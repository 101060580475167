import { Fragment } from "react";
import moment from "moment";

import Table from "@/common/Table";
import Helpers from "@/lib/helpers";

import { useUser } from "@/layout/Context";
import { formatPhone } from "@/utils";

import { Button } from "@/_v2/components/ui/button";

import { useUnit } from "./UnitContext";

type WorkOrder = {
  assigned_employee_first_name: string;
  assigned_employee_last_name: string;
  service_problem: string;
  status: string;
  status_reason: string;
  assigned_to: any; // @todo
  priority: string;
  remote_id: string;
  comments: string;
  requested_by: any; // @todo
  id: number;
  issue_location: string;
  permission_to_enter: string;
  entry_notes: string;
  resident_phone: string;
  resident_email: string;
  completed_at: string;
  inserted_at: string;
  created_at: string;
  scheduled_start_time: string;
  scheduled_end_time: string;
  scheduled_date: string;
  completed_by_name: string;
  days_open: string;
  appointment_date: string;
  scheduled_time_window: string;
  device_id: null | string;
  device_attribute_value: null | string;
};

export default function UnitWorkOrders() {
  const { permissions } = useUser();
  const { workOrders, refetchWorkOrders } = useUnit();

  let workOrdersDOM = (
    <Button className="" onClick={() => refetchWorkOrders()}>
      Fetch Work Orders
    </Button>
  );

  if (workOrders) {
    workOrdersDOM = (
      <Table
        columns={[
          {
            label: "Work Orders",
            className: "grid__sm--12 grid__md--3",
            render: (r: WorkOrder) => {
              const assignedEmployeeName = `${r.assigned_employee_first_name || ""} ${r.assigned_employee_last_name || ""}`;

              return (
                <Fragment>
                  <h5 className="h5">{r.service_problem}</h5>
                  {r.status === "Complete" ? (
                    <span className="u-text-symbolic-success">{r.status}</span>
                  ) : (
                    <span className="u-text-symbolic-warning">
                      {r.status} {r.status_reason}
                    </span>
                  )}

                  <div>
                    <div>
                      Assigned to Vendor:{" "}
                      <span className="u-text-utility-deep-purple-400">
                        {r.assigned_to}
                      </span>
                    </div>
                    <div>
                      Assigned to Employee:{" "}
                      <span className="u-text-utility-deep-purple-400">
                        {/* If the assignedEmployeeName is only the " " then we assume there wasn't a first or last name found, so therefor it's probably unassigned */}
                        {assignedEmployeeName.length === 1
                          ? "Unassigned"
                          : assignedEmployeeName}
                      </span>
                    </div>
                  </div>

                  {r.priority && <div>{r.priority} Priority</div>}
                  {r.issue_location && <div>{r.issue_location}</div>}
                  {r.permission_to_enter && (
                    <div>Entry Permission: {r.permission_to_enter}</div>
                  )}
                  {r.requested_by && <div>Requestor: {r.requested_by}</div>}
                  {r.comments && <div className="u-mtop8">{r.comments}</div>}
                  {r.entry_notes ? (
                    <div className="u-mtop8">{r.entry_notes}</div>
                  ) : null}

                  {permissions.view_technical_details && (
                    <div className="text-warning u-font12">
                      id: {r.id}, remote id: {r.remote_id || "-"}
                      {r.device_id ? `, device id: ${r.device_id}` : null}
                      {r.device_attribute_value
                        ? `, device attribute value: ${r.device_attribute_value}`
                        : null}
                    </div>
                  )}
                </Fragment>
              );
            },
          },
          {
            label: "Resident",
            className: "grid__sm--12 grid__md--3",
            render: ({ resident_phone, resident_email }: WorkOrder) =>
              permissions.resident_details ? (
                <Fragment>
                  <div>
                    {resident_phone
                      ? formatPhone({ phone: resident_phone })
                      : ""}
                  </div>
                  <div>{resident_email ? resident_email : ""}</div>
                </Fragment>
              ) : (
                <div>-</div>
              ),
          },
          {
            label: "Scheduling",
            className: "grid__sm--12 grid__md--3",
            render: (r: WorkOrder) => (
              <Fragment>
                <div>
                  Created: {Helpers.formatDate(r.created_at || r.inserted_at)}
                </div>
                <div>
                  Completed:{" "}
                  {r.completed_at ? Helpers.formatDate(r.completed_at) : "-"}
                </div>

                {r.completed_by_name && (
                  <div>Completed By: {r.completed_by_name}</div>
                )}

                {r.scheduled_date && (
                  <div>Scheduled Date: {r.scheduled_date}</div>
                )}
                {r.scheduled_start_time && (
                  <div>Scheduled Start: {r.scheduled_start_time}</div>
                )}
                {r.scheduled_end_time && (
                  <div>Scheduled End: {r.scheduled_end_time}</div>
                )}
                {r.scheduled_time_window && (
                  <div>Scheduled Time Window: {r.scheduled_time_window}</div>
                )}
                {r.appointment_date && (
                  <div>Appt. Date: {r.appointment_date}</div>
                )}
                {r.status !== "Complete" && !r.completed_at && (
                  <div>
                    {r.days_open
                      ? `${r.days_open} days open`
                      : `open for ${moment(
                          r.created_at || r.inserted_at
                        ).fromNow()}`}
                  </div>
                )}
              </Fragment>
            ),
          },
        ]}
        records={workOrders}
        className="u-mbottom16"
        classes={{
          container: "list--striped",
          chevron: "icon--gray-400",
          chevronActive: "icon--gray-500",
          header: "u-text-upper u-sans-bold u-text-gray-400",
        }}
      />
    );
  }

  return <Fragment>{workOrdersDOM}</Fragment>;
}
