import { useEffect, useMemo } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";
import { HStack, Typography, Stack, useTheme } from "@smartrent/ui";

import Helpers from "@/lib/helpers";
import { transformMessage, getColor } from "@/utils/hubLogs";

import { useHubLogs } from "@/api";

import Circle from "../Circle";

import { HubDebugTitleBar } from "./HubDebugTitleBar";

type Type = "info" | "success" | "error" | "warning";

interface HubLogProps {
  filters: Record<string, any>;
  onFetch?: (logs: Record<string, any>[]) => void;
}

function Log({
  children,
  type,
  tip,
  timestamp,
}: {
  children: React.ReactNode;
  type: Type;
  tip?: string;
  timestamp: string;
}) {
  return (
    <HStack style={styles.label} align="start">
      <Circle
        size={8}
        style={{ marginTop: 9, flexShrink: 0 }}
        color={getColor(type)}
      />
      <HStack style={styles.container} align="center" spacing={1}>
        <Typography style={styles.label} variation="regular.body.medium">
          {children}
        </Typography>
        {tip ? (
          <Typography variation="semibold.caption.medium" color="helperText">
            ({tip})
          </Typography>
        ) : null}
        <Typography variation="regular.caption.medium" color="helperText">
          {Helpers.formatDate(timestamp)}
        </Typography>
      </HStack>
    </HStack>
  );
}

export function HubLog({ message, timestamp, hubType, idx }) {
  const { colors } = useTheme();
  const { label, type, tip } = useMemo(() => {
    return transformMessage(message, hubType);
  }, [message, hubType]);

  return (
    <Stack
      style={[
        styles.log,
        idx % 2 === 0 ? { backgroundColor: colors.listBackgroundHover } : {},
      ]}
    >
      <HStack align="center" wrap={true} spacing={4}>
        <Log type={type} tip={tip} timestamp={timestamp}>
          {label}
        </Log>
      </HStack>
    </Stack>
  );
}

export default function HubLogs({ filters, onFetch }: HubLogProps) {
  const { data, isLoading, isFetching, isError, isFetched, refetch } =
    useHubLogs(filters);

  useEffect(() => {
    if (onFetch && data && isFetched) {
      onFetch(data);
    }
  }, [onFetch, isFetched, data]);

  if (!(filters && (filters.remote_id || filters.serial))) {
    return null;
  }

  return (
    <View style={styles.logContainer}>
      <HubDebugTitleBar
        title="Logs"
        isLoading={isLoading || isFetching}
        onReloadClicked={refetch}
      />

      {isLoading ? <Typography>Loading...</Typography> : null}
      {isError ? <Typography>Error loading logs</Typography> : null}

      {(data?.length ? data : []).map(({ message, timestamp }, idx) => (
        <HubLog
          message={String(message)}
          timestamp={String(timestamp)}
          hubType={filters.type}
          key={String(idx)}
          idx={idx}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  logContainer: {
    marginTop: 8,
    maxHeight: 300,
    overflowY: "auto",
  },
  log: {
    marginBottom: 5,
    paddingLeft: 5,
  },
  label: {
    display: "flex",
    flexShrink: 1,
    wordBreak: "break-all",
  },
  container: {
    flexDirection: "column",
    alignItems: "flex-start",
    display: "flex",
    flexShrink: 1,
    wordBreak: "break-word",
  },
});
