import { Breadcrumbs } from "@smartrent/ui";

import { LayoutV2 } from "@/_v2/layout/layout";
import { QuickSightDashboard } from "@/common/QuicksightDashboard";
import { useDocumentTitle } from "@/hooks/use-document-title";

export const InsightsDashboardPage = () => {
  useDocumentTitle("Insights - Client Dashboard");

  return (
    <LayoutV2 fullScreen={true}>
      <div>
        <div className="p-2">
          <Breadcrumbs items={[{ label: "SmartRent Insights" }]} />
        </div>
        <QuickSightDashboard
          id="client-dashboard"
          style={{ height: "calc(100vh - 80px)", width: "100%" }}
        />
      </div>
    </LayoutV2>
  );
};
